<template>
  <div id="home">
    <div>
      <AppHeader :title="content.navTitle" />
    </div>
    <div class="content page-wrapper">
      <div class="_grid _flex:align(start) _m:top(4)">
        <div class="_col(7) _p:right(2)">
          <p style="white-space: pre-line;">
            {{ content.introText }}
          </p>
        </div>
        <div class="_col(5) _grid">
          <div class="_m:top(2) _col(12)">
            <Card
              v-show="
                (connection === 'offline' || connection === 'unknown') &&
                  queueLength > 0
              "
              version="error"
            >
              <div
                v-if="
                  (connection === 'offline' || connection === 'unknown') &&
                    queueLength > 0
                "
                class="_m:bottom(3)"
              >
                <p>
                  <strong>Data is pending upload.</strong><br>
                  Please restore connection to upload {{ queueLength }} case{{
                    queueLength !== 1 ? "s" : ""
                  }}.
                </p>

                <Button
                  id="retry"
                  type="button"
                  :disabled="connection === 'unknown'"
                  @click="testConnection"
                >
                  {{ connection === "unknown" ? "Retrying" : "Retry" }}
                </Button>
              </div>
              <div v-if="connection === 'syncing'">
                <p>Data is uploading...</p>
                <p>
                  Uploading {{ queueLength }} case{{
                    queueLength !== 1 ? "s" : ""
                  }}.
                </p>
              </div>
            </Card>
          </div>
          <div
            v-if="$root.updateAvailable && connection === 'online'"
            class="_col(12)"
          >
            <Card>
              <p>There is a new version of the application avalible.</p>
              <Button
                type="button"
                @click="updateSw()"
              >
                Update
              </Button>
            </Card>
          </div>
          <div>
            <Card class="_col(12)">
              <Checklist
                title="check before starting"
                :items="[
                  'Check power connections, or ensure iPad is well charged',
                  'Ensure iPad clock time matches the HEMOSPHERE',
                  'Never clear iPad browser cache if an internet connection is not available and case records are pending upload',
                ]"
              />
            </Card>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <Button
        id="start"
        type="button"
        size="lg"
        block
        @click="$emit('nextStep', { createdAt: new Date() })"
      >
        Prepare new case
      </Button>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AppHeader, Button, Card, Checklist } from "../components/elements";

export default {
  name: "Home",
  components: {
    AppHeader,
    Button,
    Card,
    Checklist
  },
  computed: {
    ...mapGetters({
      connection: "tavi/connection",
      queueLength: "tavi/queueLength",
      content: 'tavi/content'
    }),
  },
  async mounted () {
    await this.fetchContent();
  },
  methods: {
    ...mapActions({
      testConnection: "tavi/testConnection",
      fetchContent: "tavi/fetchContent",
    }),
    updateSw () {
      window.swRegistration.waiting.postMessage('SKIP_WAITING');
    }
  },
};
</script>
