<template>
  <form
    id="complete"
    @submit.prevent="$emit('nextStep', caseData)"
  >
    <div
      class="_flex:column _flex:center _algin:center"
      style="height: 100vh;"
    >
      <h2 class="_m(1)">
        Thank you for filling out the information for
      </h2>
      <p>UNIQUE SESSION ID - {{ caseId }}</p>
    </div>
    <footer>
      <Button
        id="start"
        size="lg"
        block
        type="submit"
      >
        Return to start
      </Button>
    </footer>
  </form>
</template>

<script>
import stepMixin from '../mixins/step.js';
import { mapGetters } from 'vuex'
import { Button } from './elements';

export default {
  name: 'Complete',
  components: {
    Button
  },
  mixins: [stepMixin],
  computed: {
    ...mapGetters({
      caseId: 'tavi/caseId'
    })
  }
};
</script>
