import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import tavi, { plugin as taviPlugin } from './modules/tavi';

const persist = new VuexPersistence({
  storage: window.localStorage
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { tavi },
  plugins: [ persist.plugin, taviPlugin ]
});

export default store;
