import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import 'bristol-fashion/src/index.less';

// eslint-disable-next-line no-undef
console.log('Current environment', process.env.NODE_ENV);

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  window.addEventListener('load', async () => {
    if (!('serviceWorker' in navigator)) return;
    const registration = await navigator.serviceWorker.register('../sw.js');
    window.swRegistration = registration;
    // ensure the case when the updatefound event was missed is also handled
    // by re-invoking the prompt when there's a waiting Service Worker
    if (registration.waiting) {
      console.log('registration.waiting');
      window.app.updateAvailable = true;
    }

    // detect Service Worker update available and wait for it to become installed
    registration.addEventListener('updatefound', () => {
      console.log('updateFound');
      if (registration.installing) {
        console.log('registration.installing');
        // wait until the new Service worker is actually installed (ready to take over)
        registration.installing.addEventListener('statechange', () => {
          console.log('installing.statechange');
          if (registration.waiting) {
            // if there's an existing controller (previous Service Worker), show the prompt
            if (navigator.serviceWorker.controller) {
              console.log('navigator.serviceWorker.controller');
              window.app.updateAvailable = true;
            } else {
              // otherwise it's the first install, nothing to do
              console.log('Service Worker initialized for the first time')
            }
          }
        });
      }
    });

    let refreshing = false;
    // detect controller change and refresh the page
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing) {
        window.location.reload()
        refreshing = true
      }
    });

  });
}

Sentry.init({
  Vue,
  dsn: "https://29a02e416455429da164267931f48982@o200651.ingest.sentry.io/5995274",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "seam.es", "pages.dev", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

window.app = new Vue({
  data: () => ({
    updateAvailable: false
  }),
  store,
  render: h => h(App),
}).$mount('#app');
