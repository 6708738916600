<template>
  <div class="modalContainer">
    <div class="modal">
      <div
        class="modal__wrapper"
        @click="inactive = false"
      >
        <section class="modal-content _flex:column">
          <div
            v-if="hasCloseIcon"
            class="modal__closeIcon"
          >
            <span
              style="cursor: pointer;"
              @click="$emit('onCancel')"
            >
              X
            </span>
          </div>
          <div class="slot-content">
            <slot />
          </div>
          <div
            v-if="hasFooter"
            class="modal__footer"
          >
            <div class="modal__button">
              <Button
                id="start"
                type="button"
                size="lg"
                design="outlined"
                @click.native="$emit('onCancel')"
              >
                Cancel
              </Button>
            </div>
            <div class="modal__button">
              <Button
                id="start"
                type="submit"
                size="lg"
                @click.native="$emit('onConfirm')"
              >
                Confirm
              </Button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "./index";
export default {
  name: "Modal",
  components: {
    Button,
  },
  props: {
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasCloseIcon: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Number,
      default: null,
    }
  },
  data: () => ({
    inactive: true,
    timeout: null
  }),
  watch: {
    inactive: function (val) {
      if (val === false && this.autoClose) {
        clearTimeout(this.timeout)
        this.inactive = true
        this.timeout = setTimeout(() => {
          this.$emit('onCancel');
        }, this.autoClose)
      }
    }
  },
  mounted () {
    if (this.autoClose) {
      this.timeout = setTimeout(() => {
        this.$emit('onCancel');
      }, this.autoClose)
    }
  }
};
</script>
<style lang=less scoped>
.modalContainer {
  display:-webkit-flex;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  margin: auto;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  .modal {
    &__wrapper {
      position:relative;
      vertical-align: middle;
      -webkit-align-items: center;
      align-items: center;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-direction: column;
      -webkit-flex-direction: column;
      z-index: 999;
      margin: auto;
      min-width: 70vw;
      padding: 5vh 0 0;
      border-radius: 2px;
      transition: all 0.3s ease;
    }
    &__closeIcon {
      position: absolute;
      color: #000;
      top: 15px;
      right: 15px;
    }
    &__footer {
      position: relative;
      display:-webkit-flex;
      display: flex;  
      -webkit-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-direction: row;
      -webkit-flex-direction: row;
      justify-content: center;
      -webkit-justify-content: center;
      padding: 20px;
    }
    &__button {
      width: fit-content;
      margin: 0 25px;
    }
  }
}
.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 70vw;
  height: 60vh;
  padding: 2rem;
  margin: auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.slot-content {
  img {
    width: auto;
    height: 50vh;
  }
}
</style>
