<template>
  <div class="checkList _flex:column">
    <h2 class="checkList_title">
      {{ title }}
    </h2>
    <div
      v-for="item in items"
      :key="item"
      class="checkList_item _flex:row _flex:align(center) _flex:wrap(start)"
    >
      <span class="checkList_item_icon _flex:center">&#x2713;</span>
      <span class="checkList_item_text">{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checklist",
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang=less scoped>
.checkList {
  &_title {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.15rem;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    color: #c8102e;
  }
  &_item {
    padding: 1.25rem 0;
    border-bottom: 2px solid fade(#a6a9a9, 35%);
    &:last-child {
      border-bottom: none;
    }
    &_icon {
      min-width: 1.5rem;
      height: 1.5rem;
      background: #c8102e;
      border-radius: 50%;
      color: #fff;
    }
    &_text {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.4;
      color: #505759;
      margin-left: 1rem;
    }
  }
}
</style> 