<template>
  <div class="inputText _flex:column _m(0)">
    <label
      :for="name"
      class="inputText__label"
    >
      {{ label }}
    </label>
    <input
      :type="type"
      :pattern="pattern"
      :required="required"
      :name="name"
      :value="value"
      :class="`inputText__input ${size}`"
      @input="(e) => $emit('input', e.target.value)"
    >
    <span
      v-if="errorMessage.length"
      class="inputText__errorMessage"
    >
      {{
        errorMessage
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "name",
    },
    type: {
      type: String,
      default: "text",
    },
    pattern: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    }
  }
};
</script>
<style lang=less scoped>
.inputText {
  -webkit-appearance: none;
  margin: 12px;
  &__label {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.05em;
    line-height: 1;
    color: #505759;
    margin-bottom: 0.35rem;
  }
  &__input {
    border: 2px solid #a6a9a9;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Arial;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.06em;
    padding: 0.5rem;

    &.sm {
      font-size: 0.95rem;
      padding: 0.75rem;
    }
  }
  &__errorMessage {
    color: #c8102e;
    font-family: Arial;
    font-size: 1rem;
    line-height: 1;
    margin-top: 0.1rem;
  }
}
</style>