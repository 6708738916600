<template>
  <div class="questionScore _flex:row _flex:align(center)">
    <div class="questionScore__item">
      <span class="questionScore__item__question">
        {{ label }}
      </span>
    </div>

    <Button
      v-for="(score, i) in scores"
      :key="i++"
      type="button"
      class="_m:right(1)"
      size="xs"
      :design="score.value === value ? 'solid' : 'outlined'"
      shape="rounded"
      :color="score.color"
      @click="myfunc(score)"
    >
      {{ score.value }}
    </Button>
  </div>
</template>

<script>
import { Button } from "./index";

export default {
  name: "QuestionScore",
  components: {
    Button,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null
    }
  },
  data: () => {
    return {
      scores: [
        { value: 0, color: "red" },
        { value: 1, color: "light-red" },
        { value: 2, color: "orange" },
        { value: 3, color: "yellow" },
        { value: 4, color: "light-green" },
        { value: 5, color: "green" },
      ],
    };
  },
  methods: {
    myfunc (score) {
      this.$emit('input', score.value)
    }
  }
};
</script>
<style lang=less scoped>
.questionScore {
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid lighten(#a6a9a9, 5%);
  &__item {
    min-width: 60%;
    max-width: 60%;
    &__question {
      font-size: 1rem;
      line-height: 1;
      font-weight: 600;
    }
  }
  button {
    font-size: 0.9rem !important;
  }
}
</style> 