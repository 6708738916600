<template>
  <div :class="`card ${version ? version : null}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    version: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="less" scoped>
  .card {
    padding: 0.5rem 1.5rem;
    border-radius: 0.2rem;
    border: 2px solid #BBCDD1;
    background-color: #E8EEF0;
  }
  .card.error {
    color: #C8102E;
    border-color: #C8102E;
    background-color: #fbf2f4;
  }
</style>