export default {
  data: () => ({
    caseData: {}
  }),
  props: {
    caseItem: { type: Object, required: true }
  },
  watch: {
    caseItem: {
      immediate: true,
      deep: true,
      handler () {
        this.$set(this, 'caseData', { ... this.caseItem });
      }
    }
  }
};
