<template>
  <button
    :type="type"
    :class="`button _flex:center ${computedBlock} ${design} ${size} ${shape} ${color}`"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    design: {
      type: String,
      default: "solid",
    },
    shape: {
      type: String,
      default: "rounded",
    },
    size: {
      type: String,
      default: "sm",
    },
    type: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "red",
    },
  },
  computed: {
    computedBlock() {
      return this.block === true ? "block" : "inline";
    },
  },
};
</script>
<style lang=less scoped>
.button {
  font-family: Arial;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &.block {
    display: block;
    width: 100% !important;
  }
  &.circular {
    border-radius: 50%;
  }
  &.rounded {
    border-radius: 4px;
  }
  &.solid {
    color: #fff;
    background-color: #c8102e;
    border: 1px solid #c8102e;
    &.red {
      color: white;
      border: 1px solid #c8102e;
      background-color: #c8102e;
    }
    &.white {
      color: #c8102e;
      border: 1px solid #fff;
      background-color: #fff;
    }
    &.light-red {
      color: white;
      border: 1px solid #cf4520;
      background-color: #cf4520;
    }
    &.orange {
      color: white;
      border: 1px solid #f89725;
      background-color: #f89725;
    }
    &.yellow {
      color: white;
      border: 1px solid #d8b604;
      background-color: #d8b604;
    }
    &.light-green {
      color: white;
      border: 1px solid #a0b95a;
      background-color: #a0b95a;
    }
    &.green {
      color: white;
      border: 1px solid #008000;
      background-color: #008000;
    }
  }

  &.outlined {
    &.red {
      color: #c8102e;
      background-color: #fff;
      border: 1px solid #c8102e;
      &:hover {
        background-color: #c8102e;
        color: #fff;
      }
    }
    &.light-red {
      color: #cf4520;
      background-color: #fff;
      border: 1px solid #cf4520;
      &:hover {
        background-color: #cf4520;
        color: #fff;
      }
    }
    &.orange {
      color: #f89725;
      background-color: #fff;
      border: 1px solid #f89725;
      &:hover {
        background-color: #f89725;
        color: #fff;
      }
    }
    &.yellow {
      color: #d8b604;
      background-color: #fff;
      border: 1px solid #d8b604;
      &:hover {
        background-color: #d8b604;
        color: #fff;
      }
    }
    &.light-green {
      color: #a0b95a;
      background-color: #fff;
      border: 1px solid #a0b95a;
      &:hover {
        background-color: #a0b95a;
        color: #fff;
      }
    }
    &.green {
      color: #008000;
      background-color: #fff;
      border: 1px solid #008000;
      &:hover {
        background-color: #008000;
        color: #fff;
      }
    }
  }
  &.flat {
    color: #c8102e;
    background-color: #fff;
    border: none;
  }
  &.xs {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: normal;
    width: 40px;
    height: 40px;
    padding: 15px 20px;
  }
  &.sm {
    width: fit-content;
    font-size: 0.85rem;
    padding: 0.35em 1rem;
  }
  &.md {
    width: fit-content;
    font-size: 1.15rem;
    padding: 0.35em 1rem;
  }
  &.lg {
    width: 100%;
  }
  &:hover {
    background-color: #feb2b2;
    border: 1px solid #feb2b2;
    -webkit-tap-highlight-color: transparent;
  }
}
</style>