<template>
  <form
    id="feedback"
    @submit.prevent=""
  >
    <div>
      <AppHeader title="Feedback" />
    </div>
    <div class="content-sm _m:top(3)">
      <strong style="font-size: 1.25rem;">How much do you agree or disagree with the following statements? <br><span style="color: red">0 = Strongly disagree</span>&nbsp;|&nbsp;<span style="color: green">5 = Strongly agree</span></strong>
      <div>
        <QuestionScore
          v-model="caseData.feedbackEaseOfUse"
          label="HPI is easy to use"
        />
      </div>
      <div>
        <QuestionScore
          v-model="caseData.feedbackDiagnoseTreatmentRequiered"
          label="HPI improved my ability to diagnose if a treatment was required"
        />
      </div>
      <div>
        <QuestionScore
          v-model="caseData.feedbackDiagnoseWhichTreatmentRequiered"
          label="HPI improved my ability to diagnose which treatment was required"
        />
      </div>
      <div>
        <QuestionScore
          v-model="caseData.feedbackTreatedHyotensionSooner"
          label="HPI allowed me to treat hypotension sooner / before it occurred"
        />
      </div>
      <div>
        <QuestionScore
          v-model="caseData.feedbackChangedMyPractice"
          label="HPI changed my practice"
        />
      </div>
      <div>
        <QuestionScore
          v-model="caseData.feedbackWouldLikeAccessAfterProject"
          label="I would like to have access to HPI after this project"
        />
      </div>
      <div class="_m:top(4)">
        <InputText
          v-model="caseData.feedbackAdditionalComment"
          label="Any additional comments?"
        />
      </div>
    </div>
    <footer>
      <Button
        id="start"
        size="lg"
        block
        type="submit"
        @click.native="validate"
      >
        Finish
      </Button>
    </footer>
  </form>
</template>

<script>
import stepMixin from '../mixins/step.js';
import { QuestionScore, InputText, AppHeader, Button } from './elements';
export default {
  name: 'Feedback',
  components: {
    QuestionScore,
    InputText,
    AppHeader,
    Button
  },
  mixins: [stepMixin],
  methods: {
    validate () {
      this.caseData.feedbackEaseOfUse != undefined &&
      this.caseData.feedbackDiagnoseTreatmentRequiered != undefined &&
      this.caseData.feedbackDiagnoseWhichTreatmentRequiered != undefined &&
      this.caseData.feedbackTreatedHyotensionSooner != undefined &&
      this.caseData.feedbackChangedMyPractice != undefined &&
      this.caseData.feedbackWouldLikeAccessAfterProject != undefined ? this.valid() : this.invalid()
    },
    invalid() {
      alert('please complete the feedback questions before finishing the case')
    },
    valid () {
      this.$emit('nextStep', this.caseData)
    }
  }
};
</script>
