<template>
  <!-- <form
    id="intraop"
    @submit.prevent="$emit('nextStep', caseData)"
  > -->
  <div id="intraop">
    <AppHeader
      title="Intraoperative audit entry"
      :subtitle="`UNIQUE SESSION ID - ${caseId}`"
    >
      <template #back>
        <Button
          size="md"
          design="solid"
          type="button"
          color="white"
          @click="goToPrevStep"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="none"
              d="M0 0h24v24H0z"
            />
            <path
              fill="#c8102e"
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </Button>
      </template>
      <template #actions>
        <Button
          size="md"
          design="solid"
          type="button"
          color="white"
          @click.native="showProtocolModal = true"
        >
          View&nbsp;Protocol
        </Button>
        <Modal
          v-if="showProtocolModal"
          :has-footer="false"
          :has-close-icon="true"
          @onCancel="() => (showProtocolModal = false)"
        >
          <div>
            <img :src="content.protocolImage">
          </div>
        </Modal>
      </template>
    </AppHeader>
    <div class="content">
      <div class="_grid">
        <div class="_col(4) _p(2)">
          <div class="_align:center _m:y(2)">
            <h2 class="_m(0)">
              Preload
            </h2>
            <span>Fluids</span>
          </div>
          <div
            v-for="(item, i) in options"
            :key="i++"
          >
            <EventCard
              v-if="item.type === 'preload'"
              :title="item.name"
              :subtitle="item.desc"
              :count="item.count"
              :color="item.color"
              @click="() => openModal(item)"
            />
          </div>
        </div>
        <div class="_col(4) _p(2)">
          <div class="_align:center _m:y(2)">
            <h2 class="_m(0)">
              Afterload
            </h2>
            <span>Vasopressors</span>
          </div>
          <div
            v-for="(item, i) in options"
            :key="i++"
          >
            <EventCard
              v-if="item.type === 'afterload'"
              :title="item.name"
              :subtitle="item.desc"
              :count="item.count"
              :color="item.color"
              @click="() => openModal(item)"
            />
          </div>
        </div>
        <div class="_col(4) _p(2)">
          <div class="_align:center _m:y(2)">
            <h2 class="_m(0)">
              Contracility
            </h2>
            <span>Intropes</span>
          </div>
          <div
            v-for="(item, i) in options"
            :key="i++"
          >
            <EventCard
              v-if="item.type === 'contracility'"
              :title="item.name"
              :subtitle="item.desc"
              :count="item.count"
              :color="item.color"
              @click="() => openModal(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <footer>
      <Button
        v-if="confirm === 'awaiting' || confirm === 'completed'"
        id="start"
        size="lg"
        block
        type="submit"
        @click="() => (confirm = 'pending')"
      >
        <span v-if="confirm === 'completed'"> Case finished </span>
        <span v-else> Finish Case </span>
      </Button>
      <Button
        v-if="confirm === 'pending'"
        id="start"
        size="lg"
        color="white"
        block
        type="submit"
        @click="submitIntraOp"
      >
        Click again to confirm finish
      </Button>
    </footer>
    <div
      v-if="confirm === 'pending' || confirm === 'completed'"
      class="overlay"
      @click="confirm = null"
    >
&nbsp;
    </div>
    <form @submit.prevent="addEvent">
      <Modal
        v-if="showModal"
        :auto-close="10000"
        @onCancel="closeModal"
      >
        <div class="_align:center _flex:center _flex:column event-modal">
          <h1>{{ selected.name }}</h1>
          <p>{{ selected.desc }}</p>
          <div class="_p(4)">
            <InputTime v-model="timeLogSetTimer" />
          </div>
        </div>
      </Modal>
    </form>
  </div>
  <!-- </form> -->
</template>

<script>
import { mapGetters } from "vuex";
import stepMixin from "../mixins/step.js";
import {
  EventCard,
  AppHeader,
  Modal,
  Button,
  InputTime,
} from "../components/elements";
export default {
  name: "IntraOp",
  components: {
    EventCard,
    AppHeader,
    Modal,
    Button,
    InputTime,
  },
  mixins: [stepMixin],
  data: () => ({
    showModal: false,
    showProtocolModal: false,
    selected: {},
    timeLogSetTimer: new Date(),
    timeLogOpenModal: null,
    uuid: null,
    confirm: 'awaiting',
    options: [
      {
        name: "Bolus",
        desc: "< 250ml",
        count: 0,
        color: "light-blue",
        category: "fluids",
        type: "preload",
        applicationTimes: [],
      },
      {
        name: "Bolus",
        desc: "251 - 500ml",
        count: 0,
        color: "mid-blue",
        category: "fluids",
        type: "preload",
        applicationTimes: [],
      },
      {
        name: "Bolus",
        desc: "> 500ml",
        count: 0,
        color: "dark-blue",
        category: "fluids",
        type: "preload",
        applicationTimes: [],
      },
      {
        name: "Small bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "light-purple",
        category: "Vasopressors",
        type: "afterload",
        applicationTimes: [],
      },
      {
        name: "Medium bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "mid-purple",
        category: "Vasopressors",
        type: "afterload",
        applicationTimes: [],
      },
      {
        name: "Large bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "dark-purple",
        category: "Vasopressors",
        type: "afterload",
        applicationTimes: [],
      },
      {
        name: "Small bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "light-green",
        category: "fluids",
        type: "contracility",
        applicationTimes: [],
      },
      {
        name: "Medium bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "mid-green",
        category: "fluids",
        type: "contracility",
        applicationTimes: [],
      },
      {
        name: "Large bolus",
        desc: "or increase to infusion",
        count: 0,
        color: "dark-green",
        category: "fluids",
        type: "contracility",
        applicationTimes: [],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      caseId: "tavi/caseId",
      content: 'tavi/content'
    }),
  },
  watch: {
    confirm: function (val) {
      if (val === 'pending') setTimeout(() => { this.confirm = 'awaiting' }, 20000);
    }
  },
  mounted() {
    this.caseData.intraOp ? (this.options = this.caseData.intraOp) : null;
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.selected = null;
      this.timeLogOpenModal = null;
    },
    openModal(item) {
      this.selected = item;
      this.showModal = true;
      this.timeLogOpenModal = new Date();
      this.timeLogSetTimer = new Date();
    },
    addEvent() {
      this.showModal = false;
      // this.timeLogSetTimer = new Date();
      this.selected.count = this.selected.count + 1;
      this.selected.applicationTimes.push( {
          originalInteraction: this.timeLogOpenModal, 
          finalValue: this.timeLogSetTimer
      });
    },
    submitIntraOp() {
      this.confirm = 'completed'
      setTimeout(() => {
        this.caseData.intraOp = this.options;
        this.$emit("nextStep", this.caseData);
      }, 1000);
    },
    goToPrevStep() {
      this.caseData.intraOp = this.options;
      this.$emit("prevStep", this.caseData);
    },
    closeProtocolModal() {
      this.showProtoclModal = false;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
footer {
  z-index: 10;
}
.event-modal {
  font-size: 2rem;
}

.event-modal h1,
.event-modal p {
  line-height: 1;
  margin: 0;
}
</style>