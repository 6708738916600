<template>
  <div>
    <div v-if="!hasDeviceId || !contentLoaded">
      Your device has lost the local storage cache as such you need to reconnet to an active internet connection in order to reinstall the APP.
    </div>
    <div v-else>
      <component
        :is="step"
        :case-item="caseItem"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />
    </div>
  </div>
</template>

<script>
import steps from './steps.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { ...steps },
  data: () => ({
    steps: Object.keys(steps),
    step: 'Home',
    caseItem: {}
  }),
  computed: {
    ...mapGetters({
      hasDeviceId: 'tavi/hasDeviceId',
      contentLoaded: 'tavi/contentLoaded'
    }),
  },
  methods: {
    ...mapActions({
      addCase: 'tavi/addCase'
    }),
    nextStep (caseItemData = {}) {
      this.$set(this, 'caseItem', caseItemData);

      const idx = this.steps.findIndex(s => s === this.step);
      if (idx < this.steps.length - 1) {
        this.step = this.steps[idx + 1];
      } else {
        this.step = 'home';
      }

      if (this.step === 'Complete') {
        this.addCase(this.caseItem);
        this.$set(this, 'caseItem', { });
      } 
    },
    prevStep (caseItemData = {}) {
      if (Object.keys(caseItemData).length) this.$set(this, 'caseItem', caseItemData);
      const idx = this.steps.findIndex(s => s === this.step);
      if (idx > 0) {
        this.step = this.steps[idx - 1];
      }
    }
  }
};
</script>

<style>
  * {
    box-sizing: border-box;
  }
  .content {
    margin: 0 auto;
    padding: 0 3rem;
  }
  .content-sm {
    margin: 0 auto;
    padding: 0 15vw;
  }
  body {
    font-family: sans-serif;
    line-height: 1.5;
    font-size: 16px;
    color: #505759;
  }

  footer {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    width: 100%;
  }

  .page-wrapper {
    margin-bottom: 10rem;
  }
</style>