<template>
  <form
    id="preop"
    @submit.prevent="$emit('nextStep', caseItem)"
  >
    <AppHeader title="Pre op audit entry">
      <template #back>
        <Button
          size="md"
          design="solid"
          type="button"
          color="white"
          @click="goToPrevStep"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="none"
              d="M0 0h24v24H0z"
            />
            <path
              fill="#c8102e"
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </Button>
      </template>
      <template #actions>
        <Button
          id="view-protocol"
          size="md"
          design="solid"
          type="button"
          color="white"
          @click.native="showProtocolModal = true"
        >
          View&nbsp;Protocol
        </Button>
        <Modal
          v-if="showProtocolModal"
          :has-footer="false"
          :has-close-icon="true"
          @onCancel="() => (showProtocolModal = false)"
        >
          <div
            id="protocol-modal"
            class="_flex:justify(center)"
          >
            <img :src="content.protocolImage">
          </div>
        </Modal>
      </template>
    </AppHeader>
    <div class="content-sm">
      <div class="_grid">
        <div class="_col(12) _m:top(5)">
          <strong class="_flex _flex:justify(space-between)">
            <span>Unique ID (enter this info into the hemosphere monitor as unique id)</span>
            <span
              id="uuid"
              style="color: red; letter-spacing: 1px;"
            >{{ caseId }}</span>
          </strong>
        </div>
        <div class="_col(12) _m:top(3)">
          <Select
            v-model="caseItem.hospital"
            label="Hospital name"
            name="hospital"
            required
            :options="content.hospitals.map(h => ({
              label: h,
              value: h
            }))"
          />
        </div>
        <div class="_col(12) _m:top(3)">
          <Select
            v-model="caseItem.primaryAnaesGrade"
            label="Primary anaesthetists grade"
            name="primaryAnaesGrade"
            required
            :options="[
              {
                label: 'Consultant',
                value: 'consultant',
              },
            ]"
          />
        </div>
        <div class="_col(12) _m:y(0)">
          <Radio
            v-model="caseItem.experienceTotalHPICases"
            label="Experience of using HPI (number of cases)"
            name="experienceTotalHPICases"
            required
            :options="[
              {
                label: '0 - 4',
                value: '0 - 4',
                color: 'red',
              },
              {
                label: '5 - 9',
                value: '5 - 9',
                color: 'red',
              },
              {
                label: '10+',
                value: '10+',
                color: 'red',
              },
            ]"
          />
        </div>
        <div class="_col(12) _m:y(0)">
          <Radio
            v-model="caseItem.isLaproscopic"
            label="Is this surgery planned to be laproscopic?"
            name="isLaproscopic"
            required
            :options="[
              {
                label: 'YES',
                value: 'yes',
                color: 'red',
              },
              {
                label: 'NO',
                value: 'no',
                color: 'red',
              },
            ]"
          />
        </div>
        <div class="_col(12) _m:top(3)">
          <strong style="color: red">
            You agree to follow the hemodynamic protocol where clinically
            relevant, entering relevant interventions into this app
          </strong>
        </div>
      </div>
    </div>
    <footer>
      <Button
        id="start"
        size="lg"
        block
        type="submit"
      >
        Start case
      </Button>
    </footer>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import stepMixin from "../mixins/step.js";
import {
  AppHeader,
  Button,
  Modal,
  Radio,
  Select,
} from "../components/elements";

export default {
  name: "PreOp",
  components: {
    AppHeader,
    Button,
    Radio,
    Modal,
    Select,
  },
  mixins: [stepMixin],
  data: () => ({
    uuid: null,
    showModal: false,
    showProtocolModal: false,
  }),
  computed: {
    ...mapGetters({
      caseId: "tavi/caseId",
      content: "tavi/content"
    }),
  },
  methods: {
    goToPrevStep() {
      if (window.confirm('Do you want to abandon the case and return to the home screen?')) {
        this.$emit("prevStep");
      }
    }
  }
};
</script>
