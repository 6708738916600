<template>
  <div class="inputTime _flex:row _flex:align(center)">
    <Button
      type="button"
      size="xs"
      design="solid"
      shape="circular"
      @click.native="decrease"
    >
      -
    </Button>
    <input
      :value="value"
      class="time"
    >
    <div class="time-display">
      {{ displayTime }}
    </div>
    <Button
      type="button"
      size="xs"
      design="solid"
      shape="circular"
      @click.native="increase"
    >
      +
    </Button>
  </div>
</template>

<script>
import { Button } from "./index";

export default {
  name: "InputTime",
  components: {
    Button,
  },
  props: {
    value: {
      type: Date,
      required: true
    },
  },
  computed: {
    displayTime () {
      return `${this.value.getHours() < 10 ? '0' + this.value.getHours() : this.value.getHours()}:${this.value.getMinutes() < 10 ? '0' + this.value.getMinutes() : this.value.getMinutes()}`
    }
  },
  methods: {
    decrease() {
      const newTime = new Date(this.value.getTime());
      newTime.setMinutes(newTime.getMinutes() - 1);
      this.$emit('input', newTime);
    },
    increase() {
      const newTime = new Date(this.value.getTime());
      newTime.setMinutes(newTime.getMinutes() + 1);
      this.$emit('input', newTime);
    },
  },
};
</script>
<style lang=less scoped>
.inputTime {
  width: fit-content;
  margin: 12px;
  font-size: 1.5rem;
  .time {
    display: none;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #505759;
    margin: 0 10px;
  }
  .time-display {
    font-size: 2rem;
    margin: 0 1rem;
  }
}
</style>