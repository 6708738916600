<template>
  <header>
    <div class="content _flex:align(center) _flex:justify(space-between)">
      <div class="_flex:align(center)">
        <div class="back">
          <slot name="back" />
        </div>
        <span>
          <strong>{{ title }}</strong> <br><span>{{ subtitle }}</span>
        </span>
      </div>
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    title: {
      type: String,
      default: 'Edwards HPI Quality Improvement Project'
    },
    subtitle: {
      type: String,
      default: ''
    },
  },
  data: () => ({})
}
</script>

<style scoped>
  header {
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), linear-gradient(180deg, #8A2A2B -33.33%, #9B2743 58.11%, #C8102E 150.2%);
    color: white;
    padding: 1rem 0rem;
  }
  header span {
    line-height: 1;
    font-size: 1.75rem;
    letter-spacing: 1px;
  }

  header span > span {
    line-height: 1;
    font-size: 1.15rem;
    letter-spacing: 0px;
  }

  header .back > * {
    margin-right: 1.2rem;
  }
</style>