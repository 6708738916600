<template>
  <div class="radio _flex:column _flex:wrap _flex:justify(center)">
    <h4 class="radio__label _m:y(3)">
      {{ label }}
    </h4>
    <div class="radio__input">
      <label
        v-for="(option, i) in options"
        :key="i++"
        class="radio__input__label"
        :data-value="name"
      >
        <input
          type="radio"
          :checked="value == option.value"
          :value="option.value"
          :name="name"
          :required="required"
          @click="(e) => $emit('input', e.target.value)"
        >
        <span :class="`radio__input__span ${option.color}`">
          {{ option.value }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radio",
  props: {
    name: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    picked: function() {
      this.$emit('input', this.picked);
    }
  }
};
</script>
<style lang=less scoped>
.radio__input__label + .radio__input__label {
  margin-left: 1.5rem;
}

.radio {
  position: relative;
  &__label {
    font-family: Arial;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    color: #505759;
    height: fit-content;
  }
  &__input {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
    flex-basis: 0;
    input {
      z-index: -1;
      position: absolute;
      opacity: 0;
      bottom: 1rem;
    }
    &__label {
      flex: 1 1 0;
      display: inline-block;
      cursor: pointer;
      margin: 0;
    }
    &__span {
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      font-family: Arial;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1;
      padding: 1rem;
      transition: all 0.25s linear;
      text-align: center;
      text-transform: uppercase;
      flex: 1 1 0;
    }
    input:checked + span {
      border-radius: 4px;
      color: #fff;
      &.red {
        color: #fff;
        background-color: #c8102e;
      }
      &.orange {
        color: #fff;
        background-color: #f89725;
      }
      &.yellow {
        color: #fff;
        background-color: #d8b604;
      }
      &.green {
        color: #fff;
        background-color: #a0b95a;
      }
      &.dark_green {
        color: #fff;
        background-color: #008000;
      }
    }
    .red {
      color: #c8102e;
      border: 2px solid #c8102e;
    }
    .orange {
      color: #f89725;
      border: 2px solid #f89725;
    }
    .yellow {
      color: #d8b604;
      border: 2px solid #d8b604;
    }
    .green {
      color: #a0b95a;
      border: 2px solid #a0b95a;
    }
    .dark_green {
      color: #008000;
      border: 2px solid #008000;
    }
  }
}
</style> 