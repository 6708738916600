import Home from './components/home.vue';
import Preop from './components/preop.vue';
import Intraop from './components/intraop.vue';
import Postop from './components/postop.vue';
import Complete from './components/complete.vue';
import Feedback from './components/feedback.vue';

export default {
  Home,
  Preop,
  Intraop,
  Postop,
  Feedback,
  Complete
};
