<template>
  <div
    :class="`select _flex:column _flex:wrap ${open ? 'active' : ''} ${size}`"
  >
    <label
      :for="name"
      class="select__label"
    >
      {{ label }}
    </label>
    <select
      v-model="selected"
      :name="name"
      :required="required"
      :class="`select__select`"
      @focus="open = !open"
      @blur="open = !open"
      @change="selectChange"
    >
      <option
        value=""
        disabled
        selected
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(option, i) in options"
        :key="i++"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    selectChange(e) {
      this.$emit("input", e.target.value);
      this.open = !this.open;
    },
  },
};
</script>
<style lang=less scoped>
.select {
  position: relative;

  &.sm {
    select {
      font-size: 0.95rem;
      padding: 0.68rem;
    }
  }
  &:after {
    content: "\25BC";
    position: absolute;
    transform: rotate(0);
    bottom: 1.25rem;
    color: darken(#a6a9a9, 10%);
    right: 1.25rem;
    transition: all 0.2s ease-in;
    transform-origin: center;
    height: 1rem;
    width: 1rem;
  }

  &.active:after {
    transform: rotate(180deg);
    transform-origin: center;
    transition: all 0.2s ease-in;
    bottom: 1rem;
  }

  &.sm {
    &:after {
      bottom: 0.85rem;
      right: 0.75rem;
    }
    &.active:after {
      bottom: 0.5rem;
    }
  }

  &__label {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.05em;
    line-height: 1.05;
    color: #505759;
    margin-bottom: 0.35rem;
  }

  select {
    -webkit-appearance: none;
    background: #fff;
    border: 2px solid #a6a9a9;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Arial;
    font-size: 1.3rem;
    line-height: 1.3;
    letter-spacing: 0.06em;
    padding: 0.9rem;
    color: #a6a9a9;

    option {
      padding-right: 10rem;
    }
  }
  select:hover,
  select:focus {
    background-color: white;
  }
}
</style>