import Vue from 'vue';
import { createClient } from '@supabase/supabase-js'

let SUPABASE_URL;
let SUPABASE_KEY;
// eslint-disable-next-line no-undef
SUPABASE_URL = process.env.VUE_APP_SUPABASE_URL;
// eslint-disable-next-line no-undef
SUPABASE_KEY = process.env.VUE_APP_SUPABASE_KEY;

if (SUPABASE_URL === 'https://aruzdtpdigqdsmgmqvkj.supabase.co') {
  console.warn(`WARNING! Running production database`);
}

const polling_timeout = 10000;
const connection_timeout = 5000;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export default {
  namespaced: true,
  state: () => ({
    connection: 'unknown', // unknown, online, syncing, offline
    windowConnection: window.navigator.onLine,
    pollingTimeout: null,
    cases: [],
    content: null,
    deviceId: null,
    nextId: null
  }),
  getters: {
    hasDeviceId: (state) => !!state.deviceId,
    deviceId: (state) => state.deviceId,
    content: (state) => state.content,
    contentLoaded: (state) => !!state.content,
    caseId: (state) => state.deviceId + state.nextId,
    connection: (state) => state.connection,
    queueLength: (state) => state.cases.length
  },
  actions: {
    // public actions
    addCase ({ dispatch, commit }, caseItem) {
      commit('addCase', caseItem);
      dispatch('sendQueue');
    },
    async fetchDeviceId ({ state, dispatch, commit }) {
      if (state.deviceId) return;
      const { data, error } = await supabase.from('device').insert([{}]);
      if (error) {
        commit('connection', 'offline');
        commit('pollingTimeout', () => dispatch('testConnection'));
        throw error;
      }
      commit('deviceId', data[0].id);
    },
    // private actions
    // queue actions
    async sendQueue ({ state, dispatch, commit }) {
      if (!state.windowConnection || state.connection !== 'online' || state.cases.length === 0) return;
      
      commit('connection', 'syncing');
      while (state.cases.length > 0) {
        await dispatch('sendFirstCase');
      }
      commit('connection', 'online');
    },
    async fetchContent ({ state, commit }) {
      if (!state.windowConnection || state.connection === 'offline') return;
      const { data, error } = await supabase.from('content').select('json');
      if (error) throw error;
      commit('content', data[0].json);
    },
    async sendFirstCase ({ state, commit, dispatch }) {
      if (state.cases.length === 0) return;

      const caseItem = state.cases[0];
      const { error } = await supabase.from('log').insert([{ data: caseItem }]);
      if (!error) {
        commit('shiftCase');
      } else {
        // work out what went wrong, connection error?
        commit('connection', 'offline');
        commit('pollingTimeout', () => dispatch('testConnection'));
        throw error;
      }
    },
    async testConnection ({ state, commit, dispatch }) {
      clearTimeout(state.pollingTimeout);
      if (!state.windowConnection || state.connection === 'online' || state.connection === 'syncing') return;
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), connection_timeout);
        await fetch('https://google.com', { mode: 'no-cors', signal: controller.signal });
        clearTimeout(timeoutId);
        
        commit('connection', 'online');
        await dispatch('fetchDeviceId');
        await dispatch('fetchContent');
        dispatch('sendQueue');
      } catch (e) {
        commit('connection', 'offline');
        commit('pollingTimeout', () => dispatch('testConnection'));
      }
    }
  },
  mutations: {
    windowConnection (state, online) {
      Vue.set(state, 'windowConnection', online);
      if (!online) Vue.set(state, 'connection', 'offline');
      else Vue.set(state, 'connection', 'unknown');
    },
    connection (state, status) {
      Vue.set(state, 'connection', status);
    },
    deviceId (state, id) {
      let deviceId = id.toString();
      while (deviceId.length < 4) deviceId = '0' + deviceId;
      state.deviceId = deviceId;
      state.nextId = 1000;
    },
    content(state, content) {
      state.content = content;
    },
    addCase (state, caseItem) {
      caseItem.deviceId = state.deviceId;
      caseItem.id = state.deviceId + state.nextId;

      state.nextId = state.nextId + 1;
      state.cases.push(caseItem);
    },
    shiftCase (state) {
      const newCases = [...state.cases];
      newCases.shift();
      Vue.set(state, 'cases', newCases);
    },
    pollingTimeout (state, callback) {
      clearTimeout(state.pollingTimeout);
      Vue.set(state, 'pollingTimeout', setTimeout(callback, polling_timeout));
    }
  }
};

export const plugin = (store) => {
  store.commit('tavi/connection', 'unknown');
  if (store.state.tavi.windowConnection) {
    store.dispatch('tavi/testConnection');
  }

  window.addEventListener('online', () => {
    store.commit('tavi/windowConnection', true);
    store.dispatch('tavi/testConnection');
  });

  window.addEventListener('offline', () => {
    store.commit('tavi/windowConnection', false);
    store.commit('tavi/connection', 'offline');
  });
};
