<template>
  <div
    :class="`eventCard _flex:column _flex:justify(center) ${color}`"
    @click="(e) => $emit('click', e)"
  >
    <h2 class="eventCard__title">
      {{ title }} <br> {{ subtitle }}
    </h2>
    <span class="eventCard__count">
      {{ count }}
    </span>
    <h4 class="eventCard__subtitle">
      This Session
    </h4>
  </div>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "red",
    },
    count: {
      type: Number,
      default: 0,
    },
    onSetCard: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style lang=less scoped>
.eventCard {
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 0.1rem;
  background: #e8eef0;
  margin: 0.5rem;
  cursor: pointer;

  &__title {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    margin: 0 0 1rem;
  }
  &__subtitle {
    font-family: Arial;
    font-weight: bold;
    font-size: 0.85rem;
    line-height: 1;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color:#ffffff;
    margin: 0.4rem;
  }
  &__count {
    font-family: Arial;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0;
  }
  &.red {
    background-color: #c8102e;
  }
  &.light-blue {
    background-color: #4281ff;
  }
  &.mid-blue {
    background-color: #3160be;
  }
  &.dark-blue {
    background-color: #0239a5;
  }
  &.light-purple {
    background-color: #b248bb;
  }
  &.mid-purple {
    background-color: #893091;
  }
  &.dark-purple {
    background-color: #5b0163;
  }
  &.light-green {
    background-color: #3197ad;
  }
  &.mid-green {
    background-color: #046e85;
  }
  &.dark-green {
    background-color: #035061;
  }
}
</style>