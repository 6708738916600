<template>
  <div>
    <div>
      <AppHeader
        title="Post op audit entry"
        :subtitle="`UNIQUE SESSION ID - ${caseId}`"
      />
    </div>
    <div class="_grid content post-op _p:top(3)">
      <div class="_col(4) post-op-inputs">
        <div class="_align:center">
          <h2>Cumulative fluid <br><br></h2>
        </div>
        <div
          v-if="auditFluid.length"
          class="_flex _m:bottom(3)"
        >
          <div style="width: 60%">
            Fluid
          </div>
          <div style="width: 20%">
            Qty
          </div>
          <div style="width: 20%">
            Unit
          </div>
        </div>
        <div
          v-for="item, i in auditFluid"
          :key="i++"
          class="_flex"
          style="margin-top: -1.1rem;"
        >
          <div class="post-op-select">
            <Select
              v-model="item.value"
              type="text"
              name="item.value"
              required
              size="sm"
              :options="options.filter(o => o.category === 'auditFluid')"
              @input="(e) => onSelect(e, item)"
            />
          </div>
          <div class="_m:left(1) post-op-input">
            <InputText
              v-model="item.qty"
              type="number"
              name="quantity"
              size="sm"
            />
          </div>
          <div class="post-op-unit">
            <!-- <div>Unit</div> -->
            <div class="_m:top(3)">
              {{ item.unit }}
            </div>
          </div>
        </div>
        <Button
          type="button"
          size="sm"
          :block="true"
          @click="() => addItem('auditFluid')"
        >
          Add new
        </Button>
      </div>
      <div class="_col(4) post-op-inputs">
        <div class="_align:center">
          <h2>Cumulative dose of vasoactive agent</h2>
        </div>
        <div
          v-if="auditVasoactiveAgent.length"
          class="_flex _m:bottom(3)"
        >
          <div style="width: 60%">
            Fluid
          </div>
          <div style="width: 20%">
            Qty
          </div>
          <div style="width: 20%">
            Unit
          </div>
        </div>
        <div
          v-for="item, i in auditVasoactiveAgent"
          :key="i++"
          class="_flex"
          style="margin-top: -1.1rem;"
        >
          <div class="post-op-select">
            <Select
              v-model="item.value"
              type="text"
              name="item.value"
              required
              size="sm"
              :options="options.filter(o => o.category === 'auditVasoactiveAgent')"
              @input="(e) => onSelect(e, item)"
            />
          </div>
          <div class="_m:left(1) post-op-input">
            <InputText
              v-model="item.qty"
              type="number"
              name="quantity"
              size="sm"
            />
          </div>
          <div class="post-op-unit">
            <!-- <div>Unit</div> -->
            <div class="_m:top(3)">
              {{ item.unit }}
            </div>
          </div>
        </div>
        <Button
          type="button"
          size="sm"
          :block="true"
          @click="() => addItem('auditVasoactiveAgent')"
        >
          Add new
        </Button>
      </div>
      <div class="_col(4) post-op-inputs">
        <div class="_align:center">
          <h2>Cumulative dose of intropic agent</h2>
        </div>
        <div
          v-if="auditIntropicAgent.length"
          class="_flex _m:bottom(3)"
        >
          <div style="width: 60%">
            Fluid
          </div>
          <div style="width: 20%">
            Qty
          </div>
          <div style="width: 20%">
            Unit
          </div>
        </div>
        <div
          v-for="item, i in auditIntropicAgent"
          :key="i++"
          class="_flex"
          style="margin-top: -1.1rem;"
        >
          <div class="post-op-select">
            <Select
              v-model="item.value"
              type="text"
              name="item.value"
              required
              size="sm"
              :options="options.filter(o => o.category === 'auditIntropicAgent')"
              @input="(e) => onSelect(e, item)"
            />
          </div>
          <div class="_m:left(1) post-op-input">
            <InputText
              v-model="item.qty"
              type="number"
              name="quantity"
              size="sm"
            />
          </div>
          <div class="post-op-unit">
            <!-- <div>Unit</div> -->
            <div class="_m:top(3)">
              {{ item.unit }}
            </div>
          </div>
        </div>
        <Button
          type="button"
          size="sm"
          :block="true"
          @click="() => addItem('auditIntropicAgent')"
        >
          Add new
        </Button>
      </div>
    </div>
    <footer>
      <Button
        id="start"
        size="lg"
        block
        type="button"
        @click="submitPostOp"
      >
        Close case and give feedback
      </Button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Select, InputText, AppHeader, Button } from '../components/elements';
import stepMixin from '../mixins/step.js';

export default {
  name: 'PostOp',
  components: {
    Select,
    InputText,
    AppHeader,
    Button
  },
  mixins: [stepMixin],
  data: () => ({
    uuid: null,
    auditFluid: [],
    auditVasoactiveAgent: [],
    auditIntropicAgent: [],
    options: null
  }),
  computed: {
    ...mapGetters({
      caseId: 'tavi/caseId',
      content: 'tavi/content'
    })
  },
  mounted () {
    this.options = this.content.fluids
  },
  methods: {
    addItem (name) {
      this[name].push({
        value: '',
        qty: null,
        unit: ''
      })
    },
    onSelect (e, item) {
      item.unit = this.options.find(o => o.value === e).unit
    },
    submitPostOp () {
      this.caseData.auditFluid = this.auditFluid
      this.caseData.auditVasoactiveAgent = this.auditVasoactiveAgent
      this.caseData.auditIntropicAgent = this.auditIntropicAgent
      this.$emit('nextStep', this.caseData)
    },
  }
};
</script>

<style scoped>
  .post-op-inputs {
    margin-bottom: 0.25rem;
    background:#eff3f4;
    border: 2px solid #e0e8ea;
    padding: 1rem;
  }
  .post-op-inputs h2 {
    line-height: 1.25;
  }
  .post-op-select {
    width: 60% !important;
  }
  .post-op-input {
    width: 20% !important;
  }
  .post-op-unit {
    width: 20% !important;
    line-height: 1;
    height: 3.75rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }
</style>
